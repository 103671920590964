import React from 'react';
import { ClubFBItem } from './../../../model/ClubFBItem';
import { IconTimeline } from '@tabler/icons-react';
import { Card, Center, Grid, Stack, Text, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'react-i18next';

function Stats({ data }: { data: ClubFBItem }) {

    const { t } = useTranslation();
    const theme = useMantineTheme();

    return (
        <Grid>
            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                <Card shadow="sm" padding="lg" radius="md" withBorder>
                    <Card.Section withBorder inheritPadding py="xs">
                        <Center style={{ height: '30vh' }}>
                            <Stack align="center">
                                <IconTimeline size={36} color={theme.colors.green[8]} />
                                <Text size="sm" style={{ marginTop: '5px', color: '#666' }}>
                                    {t('stats.inactive')}
                                </Text>
                            </Stack>
                        </Center>
                       
                    </Card.Section>
                </Card>
            </Grid.Col>
        </Grid>
    );
}

export default Stats;
