import { useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { TextInput, PasswordInput, Button, Paper, Title, Text, Group, Stack, Center, Box } from '@mantine/core';
import logo from './../../assets/logo_sm.png';

export const Login = () => {

    const { t } = useTranslation();

    const handleSubmit = useCallback(async e => {
        e.preventDefault()
        const { email, password } = e.target.elements
        const auth = getAuth()
        try {
            await signInWithEmailAndPassword(auth, email.value, password.value)
        } catch (e) {
            alert(e.message)
        }
    }, [])

    return (
        <Center style={{ height: '100vh' }}>
            <Box style={{ width: 400 }}>
                <Paper radius="md" p="xl" withBorder shadow="md">
                    <Center>
                        <img src={logo} alt="Logo" width={100} />
                    </Center>
                    <Title order={2} align="center" mt="md" mb="lg">
                        {t('login.title')}
                    </Title>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing="md">
                            <TextInput
                                name="email"
                                label={t('login.email')}
                                placeholder="your-email@example.com"
                                type="email"
                                required
                            />
                            <PasswordInput
                                name="password"
                                label={t('login.password')}
                                placeholder="Your password"
                                required
                            />
                            <Group position="apart">
                                <Button type="submit" fullWidth style={{ backgroundColor: "#008b47" }}>
                                    {t('login.button')}
                                </Button>
                            </Group>
                        </Stack>
                    </form>
                    <Text size="sm" align="center" mt="md">
                        {t('login.no.account')} <a href="/register">{t('login.no.account.register')}</a>
                    </Text>
                    <Text size="sm" align="center" mt="md">
                        {t('login.forgot.pass.title')} <a href="/reset-password">{t('login.forgot.pass.button')}</a>
                    </Text>
                </Paper>
            </Box>
        </Center>
    );
}