//Hooks
import { useState, useEffect } from 'react';
import { useFetchClubFBItem } from './../../hooks/useFetchClubFBItem';

//i18n
import { useTranslation } from 'react-i18next';

//Firebase
import { getAuth, signOut } from '@firebase/auth';
import { useAuthState, db } from './../../firebase';
import { doc, updateDoc } from "firebase/firestore";

//Models
import { ClubFBItem, SubClubItem } from './../../model/ClubFBItem';

//Mantine UI Elements
import { AppShell, Burger, Group, Button, Menu, Loader, useMantineTheme } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import classes from './NavBar.module.css';

//Components
import GolfClub from './GolfClub/GolfClub';
import Gallery from './Gallery/Gallery';
import Species from './Species/Species';
import EcoFacts from './EcoFacts/EcoFacts';
import EmployeeCodes from './Employee/EmployeeCodes';
import Stats from './Stats/Stats';
import AdditionalSettings from './AdditionalSettings/AdditionalSettings';
import Loading from './Loading/Loading';
import { ActivateCourse } from './ActivateCourse';
import { SkeletonLayout } from './SkeletonLayout';


//Assets
import logo from './../../assets/logo_sm.png';
import {
    IconGolf,
    IconKey,
    IconSettings,
    IconButterfly,
    IconChartDonut,
    IconPlant,
    IconPhoto,
    IconLogout,
    IconChevronDown
} from '@tabler/icons-react';

export function Admin() {

    const theme = useMantineTheme();
    const { t, i18n } = useTranslation();
    const { user, loading, error } = useAuthState();
    const [validationAttempt, setValidationAttempt] = useState(1);
    const [opened, { toggle }] = useDisclosure();
    const [active, setActive] = useState('Golf Club');
    const [activeComponent, setActiveComponent] = useState(Loading);
    const [subCourses, setSubCourses] = useState<SubClubItem[]>([]);
    const [selectedSubCourse, setSelectedSubCourse] = useState<SubClubItem | null>(null);
    const clubFBResponse = useFetchClubFBItem(user, validationAttempt);
 

    const retryActivation = async () => {
        setValidationAttempt(validationAttempt + 1);
    }

    const changeActiveCourseId = async () => {
        if (selectedSubCourse) {
            try {
                await updateDoc(doc(db, "clubs", user.uid), {
                    courseId: selectedSubCourse.id
                });
                retryActivation();
            } catch (e) {
                console.error("Error adding document: ", e);
            }
        }
    };

    useEffect(() => {
        if (clubFBResponse.clubFBItem.courseId !== -1) {
            setActive('Golf Club');
            setActiveComponent(() => <GolfClub data={clubFBResponse.clubFBItem} setSubCourses={setSubCourses} />);
            i18n.changeLanguage(clubFBResponse.clubFBItem.language);
        }
    }, [clubFBResponse.clubFBItem]);

    useEffect(() => {
        if (clubFBResponse.clubFBItem.courseId !== -1) {
            const subCourse = subCourses.find(item => item.id === clubFBResponse.clubFBItem.courseId);
            if(subCourse) {
                setSelectedSubCourse(subCourse);
            }
        }
    }, [subCourses, clubFBResponse.clubFBItem.courseId]);

    useEffect(() => {
        const clubItem = clubFBResponse.clubFBItem;
        if (clubItem.courseId !== -1 && selectedSubCourse) {
            if (clubItem.courseId !== selectedSubCourse.id) {
                try {
                    changeActiveCourseId();
                } catch (e) {
                    console.error("Error adding document: ", e);
                }
            }
        }
    }, [selectedSubCourse]);


    if (loading) {
        return <Loader color="green" />;
    }

    if (error) {
        return <div>Error: {error.message}</div>;  
    }


    const menuItems = [
        {
            link: '', label: 'Golf Club', icon: IconGolf, component: () => <GolfClub data={clubFBResponse.clubFBItem} setSubCourses={setSubCourses} /> },
        { link: '', label: 'Gallery', icon: IconPhoto, component: () => <Gallery data={clubFBResponse.clubFBItem} /> },
        { link: '', label: 'Species', icon: IconButterfly, component: () => <Species data={clubFBResponse.clubFBItem} /> },
        { link: '', label: 'Facts', icon: IconPlant, component: () => <EcoFacts data={clubFBResponse.clubFBItem} /> },
        { link: '', label: 'Employee Codes', icon: IconKey, component: () => <EmployeeCodes data={clubFBResponse.clubFBItem} /> },
        { link: '', label: 'Statistics', icon: IconChartDonut, component: () => <Stats data={clubFBResponse.clubFBItem} /> },
        { link: '', label: 'More', icon: IconSettings, component: () => <AdditionalSettings data={clubFBResponse.clubFBItem} /> },
    ];

    const links = menuItems.map((item) => (
        <a
            className={classes.link}
            data-active={item.label === active || undefined}
            href={item.link}
            key={item.label}
            onClick={(event) => {
                event.preventDefault();
                setActive(item.label);
                setActiveComponent(item.component);
            }}
            style={{
                backgroundColor: item.label === active ? theme.colors.brand[7] : 'white',
                color: item.label === active ? 'white' : '#000000', 
                padding: '10px 15px', 
                textDecoration: 'none', 
                display: 'flex', 
                alignItems: 'center', 
            }}
        >
            <item.icon style={{ color: item.label === active ?  'white' : 'black' }} className={classes.linkIcon} stroke={1.5} />
            <span>{t(`Admin.menu.${item.label}`)}</span>
        </a>
    ));

    let navMenuContent;
    if (clubFBResponse.loadingFBState !== 2) {
        navMenuContent = <SkeletonLayout />
    } else {
        navMenuContent = links
    }

    let mainContent;
    if (clubFBResponse.loadingFBState === 0) {
        mainContent = (<></>);
    } else if (clubFBResponse.loadingFBState === 1) {
        mainContent = <ActivateCourse retryActivation={retryActivation} />;
    } else {
         mainContent = activeComponent;
    }

    const subCourseMenuItems = subCourses.map((element) => (
        <Menu.Item
            onClick={() => setSelectedSubCourse(element)}>{element.course_name}
        </Menu.Item>
    ));

    return (
        <AppShell
            header={{ height: 60 }}
            navbar={{ width: 280, breakpoint: 'sm', collapsed: { mobile: !opened } }}
            padding="md">
            <AppShell.Header style={{ backgroundColor: '#fff', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Group h="100%" px="md">
                    <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
                    <img src={logo} alt="Logo" height={54} />
                </Group>
                <Group>

                    {subCourses.length > 0 &&
                        <Menu>
                            <Menu.Target>
                                <Button
                                    style={{
                                        color: 'black',
                                        border: theme.colors.green[7]
                             
                                    }}
                                    variant="outline"
                                    rightSection={<IconChevronDown size={16} />}
                                >
                                    {selectedSubCourse?.course_name}
                                </Button>
                            </Menu.Target>
                            <Menu.Dropdown>
                                {subCourseMenuItems}
                            </Menu.Dropdown>
                        </Menu>
                    }
                    <Button
                        leftSection={<IconLogout size={14} />}
                        style={{ backgroundColor: theme.colors.green[7], color: 'white', marginRight: 10 }}
                        onClick={() => signOut(getAuth())}
                    >
                        Abmelden
                    </Button>
                </Group>
            </AppShell.Header>
            <AppShell.Navbar p="md">
                {navMenuContent}
            </AppShell.Navbar>
            <AppShell.Main style={{ backgroundColor: "#f8f9fa" }}>
                {mainContent}
            </AppShell.Main>
        </AppShell>
    );
}