import { useEffect, useState } from "react"
import { doc, getDoc } from "firebase/firestore";
import { ClubFBResponse, ClubFBItem } from './../model/ClubFBItem';
import { db } from './../firebase';

export function useFetchClubFBItem(user: any, attempt: number) {
    const defaultClubFBItem: ClubFBItem = {
        ngaKey: "",
        courseId: -1,
        ownerId: "",
        language: "de",
        subCourses: []
    }
    const [clubFBItem, setClubFBItem] = useState<ClubFBItem>(defaultClubFBItem);
    const [loadingFBState, setLoadingFBState] = useState(0);

    useEffect(() => {
        const fetchItem = async () => {
            try {
                const docRef = doc(db, "clubs", user.uid);
                const docSnap = await getDoc(docRef);
                if (docSnap.exists()) {
                    const clubFBData = docSnap.data() as ClubFBItem;
                    if (clubFBData != null) {
                        if (clubFBData.subCourses == null) {
                            clubFBData.subCourses = [];
                        }
                    }
                    await setClubFBItem(clubFBData);
                    setLoadingFBState(2);
                } else {
                    setLoadingFBState(1);
                }
            } catch (error) {
                console.error("Error fetching document:", error);
                setLoadingFBState(1);
            }
        }
        fetchItem();
    }, [user, attempt]);
    const response: ClubFBResponse = {
        clubFBItem: clubFBItem,
        loadingFBState: loadingFBState
    }
    return response;

}