import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AspectRatio, Button, Divider, Image, Input, Switch, Text, Textarea, useMantineTheme } from '@mantine/core';
import GalleryDropzone from './../Gallery/GalleryDropzone'
import { ClubFBItem } from './../../../model/ClubFBItem';
import { ClubFactItem } from './../../../model/ClubFactItem';
import { restAPIClient } from './../../../api/Client';
import { HoleSelection } from './../Species/HoleSelection';
interface EcoFactsFormProps {
    data: ClubFBItem;
    fetchData: () => void;
}

export function EcoFactsForm({ data, fetchData}: EcoFactsFormProps ) {

    const { t } = useTranslation();
    const theme = useMantineTheme();

    const defaultFactItem: ClubFactItem = {
        id: 0,
        created_at: "",
        title: "",
        description: "",
        image: "",
        featured: 0,
        holes: []
    }
    const [fact, setFact] = useState<ClubFactItem>(defaultFactItem);
    const [selectedHoles, setSelectedHoles] = useState<string[]>([]);

    const uploadNewImage = (downloadUrl: String) => {
        const newFactData = { ...fact, image: downloadUrl as string }
        setFact(newFactData)
    }

    const arrayToCommaSeparatedString = (arr: string[]): string => {
        return arr.join(", ");
    };

    const savePost = () => {
        const token: any = data.ngaKey;
        restAPIClient.post(`/course_facts.php?course_id=${data.courseId}`, {
            title: fact.title,
            description: fact.description,
            image: fact.image,
            holes: arrayToCommaSeparatedString(selectedHoles),
            featured: fact.featured
        }, {
            headers: {
                'X-Auth-Key': token
            }
        }).then((response) => {
            if (response.status === 200) {
                try {
                    const ngaStatus = response.data["status"];
                    if (ngaStatus === "200") {
                        setFact(defaultFactItem);
                        fetchData();
                    } else {
                        console.log(response);
                    }
                } catch (e) {
                    console.error("Error adding fact: ", e);
                }
            } else {
                console.log(response);
            }
        });
    }

    const buttonDisabled = !fact.title || !fact.description;

    return (
        <>
            <div>
                {fact.image ? 
                    <>
                        <AspectRatio ratio={4 / 3}>
                            <Image
                                src={fact.image}
                                alt={`Image ${fact.image}`}
                                fit="cover"   // Ensures the image fills the container while maintaining aspect ratio
                                style={{ width: '100%', height: '100%', objectPosition: 'center' }} // Ensures center cropping
                            />
                        </AspectRatio>
                        <br/>
                        <Button onClick={() => {
                            const newFactData = { ...fact, image: "" }
                            setFact(newFactData)
                        }} color={theme.colors.green[7]} >
                            Delete image
                        </Button> 
                        <br />
                    </>
                    :
                    <GalleryDropzone data={data} uploadImageEntry={uploadNewImage} />
                }
                <br />
                <Divider />
                <br />
                <Input.Wrapper label={t('ecofacts.new.title')}>
                    <Input
                        value={fact.title}
                        onChange={(event) => {
                            const newFactData = { ...fact, title: event.currentTarget.value }
                            setFact(newFactData)
                        }}
                    />
                </Input.Wrapper>
                <br></br>
                { /* GOLF CLUB DESCRIPTION */}
                <Textarea
                    placeholder={t('ecofacts.new.description')}
                    label={t('ecofacts.new.description')}
                    autosize
                    minRows={2}
                    value={fact.description}
                    onChange={(event) => {
                        const newFactData = { ...fact, description: event.currentTarget.value }
                        setFact(newFactData)
                    }}
                />
                <br></br>
                <Switch
                    color={theme.colors.green[7]}
                    checked={fact.featured === 1}
                    onChange={(event) => {
                        const featuredValue = event.currentTarget.value ? 1 : 0;
                        const newFactData = {
                            ...fact, featured: featuredValue
                        }
                        setFact(newFactData)
                    }}
                    label={t('species.add.is.highlight')}
                />
                <br />
                <Text size="sm" style={{ marginTop: '5px', color: '#666' }}>
                    {t('ecofacts.new.holes')}
                </Text>
                <br></br>
                <HoleSelection selectedHoles={selectedHoles} setSelectedHoles={setSelectedHoles} />
                <br /><br />
                <Button onClick={savePost} color={theme.colors.green[7]} disabled={buttonDisabled}> 
                    {t('ecofacts.new.save')}
                </Button>
                <br/><br/>
            </div>
        </>
    )
}