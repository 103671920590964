import { useEffect, useState } from "react"
import { ClubFactItem } from './../model/ClubFactItem';
import { ClubFBItem } from './../model/ClubFBItem';
import { restAPIClient } from './../api/Client';

export function useFetchClubFacts(data: ClubFBItem, attempt: number) {

    const [facts, setClubFacts] = useState<ClubFactItem[]>([]);

    useEffect(() => {
        const fetchItems = async () => {
            const token: any = data.ngaKey;
            restAPIClient.get(`/course_facts.php?&course_id=${data.courseId}`, {
                headers: {
                    'X-Auth-Key': token
                }
            }).then((response) => {
                if (response.status === 200) {
                    try {
                        const ngaStatus = response.data["status"];
                        if (ngaStatus === "200") {
                            const facts = response.data["results"] as ClubFactItem[];
                            if (facts === null) {
                                setClubFacts([]);
                            } else {
                                setClubFacts(facts);
                            }
                        }
                    } catch (e) {
                        console.error("Error fetching club facts: ", e);
                    }
                } else {
                    console.log(response);
                }
            });
        }
        fetchItems();
    }, [data, attempt]);

    return facts;
}