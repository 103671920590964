import { useDisclosure } from '@mantine/hooks';
import { useState, useEffect } from 'react';
import { ClubFBItem } from './../../../model/ClubFBItem';
import { restAPIClient } from './../../../api/Client';
import { ActionIcon, Button, Center, Group, Image, Modal, Pagination , Stack, Table, Text, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { SpeciesItem, SpeciesLocalization } from '../../../model/SpeciesItem';
import { IconSeedingFilled, IconTrash, IconStar, IconStarFilled, IconGolf } from '@tabler/icons-react';
import { HoleEditor } from './HoleEditor';

interface SpeciesGridProps {
    species: SpeciesItem[];
    defaultText: string,
    clubItem: ClubFBItem,
    fetchData: () => void;
    openSpeciesCatalog: () => void;
}

export function SpeciesGrid({ species, defaultText, clubItem, fetchData, openSpeciesCatalog }: SpeciesGridProps ) {

    const { t } = useTranslation();
    const theme = useMantineTheme();
    const [opened, { open, close }] = useDisclosure(false);
    const [speciesSorted, setSpeciesSorted] = useState<SpeciesItem[]>(species);
    const [speciesEditing, setSpeciesEditing] = useState<SpeciesItem>();
    const [activePage, setActivePage] = useState(1);
    const itemsPerPage = 8;

    useEffect(() => {
        const sortSpecies = async () => {
            const sorted = species.sort((a, b) => {
                if (a.name < b.name) {
                    return -1; 
                }
                if (a.name > b.name) {
                    return 1; 
                }
                return 0; 
            });
            setSpeciesSorted(sorted);
        }
        sortSpecies();
    }, [species]);

    const totalPages = Math.ceil(speciesSorted.length / itemsPerPage);
    const startIndex = (activePage - 1) * itemsPerPage;
    const currentSpecies = speciesSorted.slice(startIndex, startIndex + itemsPerPage);

    function getLocalizedName(localizations: SpeciesLocalization[], locale: string) {
        for (const localization of localizations) {
            if (localization.locale === locale) {
                return localization.name;
            }
        }
        return ""
    }

    const deleteSpecies = (species: SpeciesItem) => {
        const confirmed = window.confirm(t('species.delete.item'));
        if (confirmed) {
            const token: any = clubItem.ngaKey;
            restAPIClient.delete(`/species.php?&course_id=${clubItem.courseId}&species_id=${species.id}`, {
                headers: {
                    'X-Auth-Key': token
                }
            }).then((response) => {
                if (response.status === 200) {
                    try {
                        fetchData();
                    } catch (e) {
                        console.error("Error deleting codes: ", e);
                    }
                } else {
                    console.log(response);
                }
            });
        } 
    }

    //edit species holes
    const editSpeciesHoles = (speciesToEdit: SpeciesItem) => {
        setSpeciesEditing(speciesToEdit);
        open();
    }


    //toggle featured
    const toggleFeatured = (speciesItem: SpeciesItem) => {
        if (speciesItem.featured === "1") {
            speciesItem.featured = "0";
        } else {
            speciesItem.featured = "1";
        }
        updateSpecies(speciesItem);
    }


    //update species
    const updateSpecies = (speciesItem: SpeciesItem) => {
        close();
        const token: any = clubItem.ngaKey;
        restAPIClient.put(`/species.php?&course_id=${clubItem.courseId}`, {
            species_id: speciesItem.id,
            holes: arrayToCommaSeparatedString(speciesItem.holes),
            featured: speciesItem.featured
        }, {
            headers: {
                'X-Auth-Key': token
            }
        }).then((response) => {
            if (response.status === 200) {
                try {
                    fetchData();
                } catch (e) {
                    console.error("Error deleting codes: ", e);
                }
            } else {
                console.log(response);
            }
        });
    } 

    //helper function to sort hole numbers in ASC order
    function sortStringNumbersSafely(arr: string[]): string[] {
        return [...arr].sort((a, b) => {
            const numA = Number(a);
            const numB = Number(b);
            if (isNaN(numA) && isNaN(numB)) return 0;   
            if (isNaN(numA)) return 1;                 
            if (isNaN(numB)) return -1;               
            return numA - numB;
        });
    }

    const rows = currentSpecies.map((element) => (
        <Table.Tr key={element.id}>
            <Table.Td>
                <Group gap="sm">
                    <Image
                        radius="md"
                        src={require(`./../../../assets/thumbs/species/${element.thumbnail}`)}
                        alt={element?.id}
                        style={{width: 100}}
                    />
                    <div>
                        <Text fz="sm" fw={500}>
                            {getLocalizedName(element.localization, "de")}
                        </Text>
                        <Text fz="xs" c="dimmed" style={{ fontStyle: 'italic' }}>
                            {getLocalizedName(element.localization, "la")}
                        </Text>
                    </div>
                </Group>
            </Table.Td>
            <Table.Td>
                {element.holes.length ?
                    <>
                        <div>
                            <Text fz="sm" fw={500} c="dimmed">
                                {t('species.grid.holes.available')}
                            </Text>
                            <Text fz="xs" c="dimmed" >
                                {arrayToCommaSeparatedString(
                                    sortStringNumbersSafely(element.holes)
                                )}
                            </Text>
                        </div>
                    </> :
                    <>
                    </>
                }
            </Table.Td>
            <Table.Td>
                <ActionIcon variant="filled" color="green" style={{ marginRight: '10px' }} aria-label="Settings" onClick={() => { editSpeciesHoles(element) }}>
                    <IconGolf style={{ width: '70%', height: '70%' }} stroke={1.5} />
                </ActionIcon>
                <ActionIcon variant="filled" color="green" style={{ marginRight: '10px' }} aria-label="Settings" onClick={() => { toggleFeatured(element) }}>
                    {element.featured === "1" ?
                        <IconStarFilled style={{ width: '70%', height: '70%' }} stroke={1.5} />
                        :
                        <IconStar style={{ width: '70%', height: '70%' }} stroke={1.5} />
                    }
                </ActionIcon>
                <ActionIcon variant="filled" color="red" aria-label="Settings" onClick={() => { deleteSpecies(element) }}>
                    <IconTrash style={{ width: '70%', height: '70%' }} stroke={1.5} />
                </ActionIcon>
            </Table.Td>
        </Table.Tr>
    ));

    return (
        <>
            <Modal opened={opened} onClose={close} title={t('species.holes.edit.title')}>
                {speciesEditing &&
                    <HoleEditor speciesEditing={speciesEditing} updateSpecies={updateSpecies} /> 
                }
            </Modal>
            {species.length ? 
                <>
                    <Table>
                        <Table.Tbody>{rows}</Table.Tbody>
                    </Table>
                    <br></br>
                    <Pagination
                        total={totalPages}
                        value={activePage}
                        onChange={setActivePage}
                        siblings={1}
                        color={theme.colors.green[6]}
                    />
                </>
                : 
                <Center style={{ height: '30vh' }}>
                    <Stack align="center">
                        <IconSeedingFilled size={36} color={theme.colors.green[8]} />
                        <Text size="sm" style={{ marginTop: '5px', color: '#666' }}>
                            {defaultText}
                        </Text>
                        <Button
                            onClick={openSpeciesCatalog}
                            style={{ backgroundColor: theme.colors.green[7], marginTop: '12px' }}>
                            {t('species.add.new')}
                        </Button>
                    </Stack>
                </Center>
            
            }
        </>
    )

}


export const arrayToCommaSeparatedString = (arr: string[]): string => {
    return arr.join(", ");
};