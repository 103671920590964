import { useEffect, useState } from "react"
import { SpeciesItem } from './../model/SpeciesItem';
import { ClubFBItem } from './../model/ClubFBItem';
import { restAPIClient } from './../api/Client';

export function useFetchClubSpecies(data: ClubFBItem, attempt: number) {

    const [species, setSpecies] = useState<SpeciesItem[]>([]);

    useEffect(() => {
        const fetchItems = async () => {
            const token: any = data.ngaKey;
            restAPIClient.get(`/species.php?&course_id=${data.courseId}`, {
                headers: {
                    'X-Auth-Key': token
                }
            }).then((response) => {
                if (response.status === 200) {
                    try {
                        const ngaStatus = response.data["status"];
                        if (ngaStatus === "200") {
                            const species = response.data["results"] as SpeciesItem[];
                            if (species !== null) {
                                setSpecies(species);
                            } else {
                                setSpecies([]);
                            }
                            //setSpecies(species);
                        }
                    } catch (e) {
                        console.error("Error fetching codes: ", e);
                    }
                } else {
                    console.log(response);
                }
            });
        }
        fetchItems();
    }, [data, attempt]);

    return species;
}