import { useState } from 'react';
import { Autocomplete, Button, Image, Stack, Switch, Text, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { SpeciesSearchItem, GroupItem } from '../../../model/SpeciesSearchItem';
import { HoleSelection } from './HoleSelection';

interface SpeciesCatalogProps {
    catalogValue: string;
    selectedItem: SpeciesSearchItem | null;
    isHighlight: boolean,
    setIsHighlight: (isHighlight: boolean) => void;
    selectedHoles: string[];
    setSelectedHoles: (holes: string[]) => void;
    groups: GroupItem[];
    handleItemSelect: (species: string) => void;
    saveSpecies: () => void;
}

export function SpeciesCatalog({ catalogValue, selectedItem, isHighlight, setIsHighlight, selectedHoles, setSelectedHoles, groups, handleItemSelect, saveSpecies }: SpeciesCatalogProps) {

    const { t } = useTranslation();
    const theme = useMantineTheme();

    return (
        <>
            <Autocomplete
                label={t("species.catalog.title")}
                placeholder={t("species.catalog.hint")}
                limit={10}
                value={catalogValue}
                data={catalogValue.trim() !== "" ? groups?.map(group => ({
                    group: t(`species.groups.${group.name}`),
                    items: Array.from(new Set(group.species.map((item) => item.name))),
                })) : []}
                onChange={handleItemSelect}
            />
            <br></br>
            {selectedItem && (
                <Stack align="center">
                    <Image
                        radius="md"
                        src={require(`./../../../assets/thumbs/species/${selectedItem?.thumbnail}`)}
                        alt={selectedItem?.name}
                        style={{ maxWidth: '200px' }}
                    />
                    <Text fw={500}>{selectedItem?.name}</Text>
                    <Switch
                        color={theme.colors.green[7]}
                        checked={isHighlight}
                        onChange={(event) => setIsHighlight(event.currentTarget.checked)}
                        label={t('species.add.is.highlight')}
                    />
                    <Text size="sm" style={{ marginTop: '5px', color: '#666', textAlign: 'center' }}>
                        {t('species.add.holes.info')}
                    </Text>
                    <HoleSelection selectedHoles={selectedHoles} setSelectedHoles={setSelectedHoles} />
                    <br></br>
                    <Button onClick={saveSpecies} color={theme.colors.green[7]} >
                        {t('species.save')}
                    </Button>
                </Stack>
            )}
        </>
    )
}