import React from 'react';
import { useState } from 'react';
import { restAPIClient } from './../../../api/Client';
import { Alert, Button, Input, Text } from '@mantine/core';
import { ClubFBItem } from './../../../model/ClubFBItem';
import { IconInfoCircle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useMantineTheme } from '@mantine/core';

interface NewEmployeeCodeModalProps {
    data: ClubFBItem,
    fetchData: () => void;
}

function NewEmployeeCodeModal({ data, fetchData }: NewEmployeeCodeModalProps) {

    const { t } = useTranslation();
    const theme = useMantineTheme();

    const [errorMessage, setErrorMessage] = useState("");
    const [newCode, setNewCode] = useState("");

    const onSubmit = () => {
        if (newCode) {
            if (newCode.length > 6) {
                setErrorMessage("");
                addEmployeeCode();
            } else {
                setErrorMessage("Code must be at least 6 characters/digits");
            }
        }
    }

    const addEmployeeCode = () => {
        const token: any = data.ngaKey;
        restAPIClient.post(`/employee_codes.php?course_id=${data.courseId}&code=${newCode}`, {}, {
            headers: {
                'X-Auth-Key': token
            }
        }).then((response) => {
            if (response.status === 200) {
                try {
                    const ngaStatus = response.data["status"];
                    if (ngaStatus === "200") {
                        fetchData();
                    } else if (ngaStatus === "-1") {
                        setErrorMessage("Code already in use by another club. Please choose a new one.")
                    } else {
                        setErrorMessage("Unknown Error occured.")
                    }
                } catch (e) {
                    console.error("Error deleting codes: ", e);
                }
            } else {
                console.log(response);
            }
        });
    }

    return (
        <div>
            <Text fw={500}>Enter New Employee Code</Text>
            <Text size="xs" c="dimmed">
                These codes can be used to authenticate customer QR Codes after scanning.
            </Text>
            <br></br>
            <Input.Wrapper label="Employee Code">
                <Input
                    value={newCode}
                    onChange={(event) => {
                        event.preventDefault();
                        setNewCode(event.currentTarget.value.toUpperCase());
                    }}
                />
            </Input.Wrapper>
            <br></br>
            {errorMessage ? 
                <>
                    <Alert variant="light" color="red" title="Error" icon={<IconInfoCircle />}>
                        {errorMessage}
                    </Alert>
                    <br></br>
                </> : <></>   
            }
            <Button onClick={onSubmit} style={{backgroundColor: theme.colors.green[7]}}>Save Code</Button>
        </div>
    );
}

export default NewEmployeeCodeModal;
