import React from 'react';
import { ClubFBItem } from './../../../model/ClubFBItem';
function Loading() {
    return (
        <div className="App">
            <p>...</p>
        </div>
    );
}

export default Loading;
