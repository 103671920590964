import { useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Group, Text, Modal, rem, useMantineTheme } from '@mantine/core';
import { IconUpload, IconPhoto, IconX } from '@tabler/icons-react';
import { Dropzone, MIME_TYPES } from '@mantine/dropzone';
import { useTranslation } from 'react-i18next';
import CropperModal from './../Cropper/CropperModal';
import { ClubFBItem } from './../../../model/ClubFBItem';


interface GalleryDropzoneProps {
    data: ClubFBItem,
    uploadImageEntry: (downloadUrl: string) => void;
}

export default function GalleryDropzone({ data, uploadImageEntry }: GalleryDropzoneProps) {

    const { t } = useTranslation();
    const theme = useMantineTheme();
    const [imgSrc, setImgSrc] = useState<string>("");
    const [opened, { open, close }] = useDisclosure(false);

    const imageSaved = (downloadUrl: string) => {
        uploadImageEntry(downloadUrl);
        close()
    }

    return (
        <>
            <Modal opened={opened} onClose={close} title="">
                <CropperModal src={imgSrc} data={data} folder={`courses/${data.courseId}`} imageSaved={imageSaved} />
            </Modal>
            <Dropzone
                accept={[
                    MIME_TYPES.jpeg,
                ]}
                multiple={false}
                onReject={
                    (files) => console.log('rejected files', files)
                }
                onDrop={(file) => {
                    
                    setImgSrc(URL.createObjectURL(file[0]));
                    open();
                }}
                maxSize={3 * 1024 ** 4}

            >
                <Group justify="center" gap="xl" mih={140} style={{ pointerEvents: 'none' }}>
                    <Dropzone.Accept>
                        <IconUpload
                            style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-blue-6)' }}
                            stroke={1.5}
                        />
                    </Dropzone.Accept>
                    <Dropzone.Reject>
                        <IconX
                            style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-red-6)' }}
                            stroke={1.5}
                        />
                    </Dropzone.Reject>
                    <Dropzone.Idle>
                        <IconPhoto
                            style={{ width: rem(52), height: rem(52), color: 'var(--mantine-color-dimmed)' }}
                            stroke={1.5}
                        />
                    </Dropzone.Idle>

                    <div>
                        <Text size="l" inline>
                            {t('dropzone.title')}
                        </Text>
                        <Text size="xs" c="dimmed" inline mt={7}>
                            {t('dropzone.filelimit')}
                        </Text>
                    </div>
                </Group>
            </Dropzone>
        </>
    );
}