import { useCallback } from 'react'
import { useTranslation } from 'react-i18next';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { TextInput, Button, Paper, Title, Text, Center, Box, Stack } from '@mantine/core';
import logo from './../../assets/logo_sm.png';

export const ForgotPassword = () => {

    const { t } = useTranslation();

    const handlePasswordReset = useCallback(async (e) => {
        e.preventDefault();
        const { email } = e.target.elements;
        const auth = getAuth();
        try {
            await sendPasswordResetEmail(auth, email.value);
            alert(t('login.forgot.pass.success.message'));
        } catch (e) {
            alert(e.message);
        }
    }, [t]);

    return (
        <Center style={{ height: '100vh' }}>
            <Box style={{ width: 400 }}>
                <Paper radius="md" p="xl" withBorder shadow="md">
                    <Center>
                        <img src={logo} alt="Logo" width={100} />
                    </Center>
                    <Title order={2} align="center" mt="md" mb="lg">
                        {t('login.forgot.pass.title')}
                    </Title>
                    <form onSubmit={handlePasswordReset}>
                        <Stack spacing="md">
                            <TextInput
                                name="email"
                                label={t('login.email')}
                                placeholder="your-email@example.com"
                                type="email"
                                required
                            />
                            <Button type="submit" fullWidth style={{ backgroundColor: "#008b47" }}>
                                {t('login.forgot.pass.button')}
                            </Button>
                        </Stack>
                    </form>
                    <Text size="sm" align="center" mt="md">
                        {t('login.forgot.pass.backtologin')} <a href="/login">{t('login.button')}</a>
                    </Text>
                </Paper>
            </Box>
        </Center>
    );
};
