import React from 'react';
import { useState } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { restAPIClient } from './../../../api/Client';
import { useFetchClubEmployeeCodes} from './../../../hooks/useFetchClubEmployeeCodes';
import { ActionIcon, Accordion, Button, Card, Center, Chip, Grid, Table, Title, Modal, Text, useMantineTheme } from '@mantine/core';
import { ClubFBItem } from './../../../model/ClubFBItem';
import NewEmployeeCodeModal from './NewEmployeeCodeModal';
import { useTranslation } from 'react-i18next';
import { IconTrash } from '@tabler/icons-react';
import { faqs, getSteps } from './EmployeeCodesInfo'

function EmployeeCodes({ data }: { data: ClubFBItem }) {

    const { t } = useTranslation();
    const theme = useMantineTheme();
    const [opened, { open, close }] = useDisclosure(false);
    const [fetchAttempt, setFetchAttempt] = useState(1);
    const employeeCodes = useFetchClubEmployeeCodes(data, fetchAttempt);

    const fetchData = () => {
        close();
        setFetchAttempt(fetchAttempt + 1);
    }

    const deleteEmployeeCode = (id: number) => {
        const confirmed = window.confirm("Are you sure you want to delete this item?");
        if (confirmed) {
            const token: any = data.ngaKey;
            restAPIClient.delete(`/employee_codes.php?&course_id=${data.courseId}&code_id=${id}`, {
                headers: {
                    'X-Auth-Key': token
                }
            }).then((response) => {
                if (response.status === 200) {
                    try {
                        setFetchAttempt(fetchAttempt + 1);
                    } catch (e) {
                        console.error("Error deleting codes: ", e);
                    }
                } else {
                    console.log(response);
                }
            });
        } 
    }

    const rows = employeeCodes?.map((element) => (
        <Table.Tr key={element.id}>
            <Table.Td>
                <Chip defaultChecked color={theme.colors.green[7]} >{element.code}</Chip>
            </Table.Td>
            <Table.Td>
                {(employeeCodes.length > 1) ?
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <div></div>
                        <ActionIcon variant="filled" color="red" aria-label="Settings" onClick={() => {deleteEmployeeCode(element.id)}}>
                            <IconTrash style={{ width: '70%', height: '70%' }} stroke={1.5} />
                        </ActionIcon>
                    </div>
                : <></>
                }
            </Table.Td>
        </Table.Tr>
    ));

    return (
        <>
            <Modal opened={opened} onClose={close} title="">
                <NewEmployeeCodeModal data={data} fetchData={fetchData} />
            </Modal>
            <Grid>
                <Grid.Col span={{ base: 12, md: 4, lg: 5 }}>
                    <Card shadow="sm" padding="lg" radius="md" withBorder>
                        <Card.Section withBorder inheritPadding py="xs" style={{ textAlign: 'center' }}>
                            {getSteps(theme).map((step, index) => (
                                <div key={index} style={{ margin: '20px 0' }}> {/* Spacing between steps */}
                                    <Center>
                                        {step.icon}
                                    </Center>
                                    <Title order={4} style={{ marginTop: '10px', fontWeight: 600 }}>
                                        {t(step.title)}
                                    </Title>
                                    <Text size="sm" style={{ marginTop: '5px', color: '#666' }}>
                                        {t(step.description)}
                                    </Text>
                                </div>
                            ))}
                        </Card.Section>
                    </Card>
                </Grid.Col>
                <Grid.Col span={{ base: 12, md: 8, lg: 7 }}>
                    <Card shadow="sm" padding="lg" radius="md" withBorder>
                        <Card.Section withBorder inheritPadding py="xs">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div></div> 
                                <Button onClick={open} style={{ backgroundColor: theme.colors.green[7] }}>
                                    {t('emplyees.codes.add')}
                                </Button>
                            </div>
                            <Table>
                                <Table.Thead>
                                    <Table.Tr>
                                        <Table.Th>Mitarbeitercodes</Table.Th>
                                        <Table.Th></Table.Th>
                                    </Table.Tr>
                                </Table.Thead>
                                <Table.Tbody>{rows}</Table.Tbody>
                            </Table>
                        </Card.Section>
                        {/* FAQ Section */}
                        <Card.Section inheritPadding py="xs" style={{ marginTop: '20px' }}>
                            <br /><br />
                            <Center>
                                <Text size="lg" fw={500} style={{ marginBottom: '10px' }}>{t('employees.faq')}</Text>
                            </Center>
                            <Accordion>
                                {faqs.map((faq, index) => (
                                    <Accordion.Item key={index} value={`faq-${index}`}>
                                        <Accordion.Control>{t(faq.question)}</Accordion.Control>
                                        <Accordion.Panel>{faq.answer}</Accordion.Panel>
                                    </Accordion.Item>
                                ))}
                            </Accordion>
                        </Card.Section>
                    </Card>
                </Grid.Col>
            </Grid>
        </>
    );
}

export default EmployeeCodes;
