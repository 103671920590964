import { useEffect, useState } from "react"
import { ClubImageItem } from './../model/ClubImageItem';
import { ClubFBItem } from './../model/ClubFBItem';
import { restAPIClient } from './../api/Client';

export function useFetchClubImages(data: ClubFBItem, attempt: number) {

    const [images, setClubImages] = useState<ClubImageItem[]>([]);
  
    useEffect(() => {
        const fetchItems = async () => {
            const token : any = data.ngaKey;
            restAPIClient.get(`/course_images.php?&course_id=${data.courseId}`, {
                headers: {
                    'X-Auth-Key': token  
                }
            }).then((response) => {
                if (response.status === 200) {
                    try {
                        const ngaStatus = response.data["status"];
                        if (ngaStatus === "200") {
                            const images = response.data["results"] as ClubImageItem[];
                            if (images === null) {
                                setClubImages([]);
                            } else {
                                setClubImages(images);
                            }
                        }
                    } catch (e) {
                        console.error("Error fetching club images: ", e);
                    }
                } else {
                    console.log(response);
                }
            });
        }
        fetchItems();
    }, [data, attempt]);

    return images;
}