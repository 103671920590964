import React from 'react';
import { useState, useEffect } from 'react';
import { useDisclosure } from '@mantine/hooks';
import { Alert, Box, Button, Card, Checkbox, Grid, LoadingOverlay, Input, Radio, Stack, Divider, Text, Textarea } from '@mantine/core';
import { ClubItem, SubClubItem, ClubFBItem } from './../../../model/ClubFBItem';
import { restAPIClient } from './../../../api/Client';
import { IconInfoCircle } from '@tabler/icons-react';
import { useTranslation } from 'react-i18next';
import { useMantineTheme } from '@mantine/core';

interface GolfClubProps {
    data: ClubFBItem,
    setSubCourses: (courses: SubClubItem[]) => void;
}

function GolfClub({ data, setSubCourses }: GolfClubProps) {

    const { t } = useTranslation();
    const theme = useMantineTheme();

    const [visibleLoadingScreen, { toggle }] = useDisclosure(false);
    const [clubItem, setClubItem] = useState<ClubItem>();
    const [baseClubItem, setBaseClubItem] = useState<ClubItem>();
    const [changesApplied, setChangesApplied] = useState(false);

    const perks: string[] = ["driving_range", "putting_green", "pro_shop", "restaurant", "cart_rental", 
    "trolly_rental", "club_rental", "rv_allowed", "dogs_allowed", ]

    function deepEqualCheck(obj1: ClubItem, obj2: ClubItem): boolean {
        if (!obj1 || !obj2) return false;
        const keys: Array<keyof ClubItem> = ['name', 'description', 'website', 'holes', 'weekdays', 'perks', 'weeks_closed', 'handicap_f', 'handicap_m', 'imprint', 'contact_name', 'contact_phone'];
        for (const key of keys) {
            if (obj1[key] !== obj2[key]) {
                return false;
            }
        }
        return true; 
    }

    function toggleItemInArray(item: string, array: string[]): string[] {
        const newArray = array.slice();
        if (array.includes(item)) {
            return newArray.filter(element => element !== item);
        } else {
            newArray.push(item);
            return newArray;
        }
    }

    function getClubData() {
        if (data.courseId !== -1) {
            restAPIClient.get(`/courses.php?clean=1&id=${data.courseId}`).then((response) => {
                if (response.status === 200) {
                    try {
                    const ngaStatus = response.data["status"];
                        if (ngaStatus === "200") {
                            const club = response.data["result"]["course"] as ClubItem;
                            if (club != null) {
                                if (club.subCourses.length > 1) {
                                    setSubCourses(club.subCourses);
                                }
                                setBaseClubItem(club);
                                setClubItem(club); 
                            }
                        }
                    } catch (e) {
                        console.error("Error processing course data: ", e);
                    }

                }
            });
        }
    }

    const arrayToCommaSeparatedString = (arr: string[]): string => {
        return arr.join(", ");
    };

    function saveClubData() {
        if (clubItem) {
            //toggle();
            const token: any = data.ngaKey;
            restAPIClient.put(`/course_edit.php?&course_id=${data.courseId}`, {
                name: clubItem.name,
                website: clubItem.website,
                phone: clubItem.phone,
                description: clubItem.description,
                holes: clubItem.holes,
                weekdays: clubItem.weekdays,
                perks: arrayToCommaSeparatedString(clubItem.perks),
                weeks_closed: arrayToCommaSeparatedString(clubItem.weeks_closed),
                imprint: clubItem.imprint,
                handicap_f: clubItem.handicap_f,
                handicap_m: clubItem.handicap_m,
                contact_name: clubItem.contact_name,
                contact_phone: clubItem.contact_phone
            }, {
                headers: {
                    'X-Auth-Key': token,
                }
            }).then((response) => {
                //toggle();
                if (response.status === 200) {
                    try {
                        setBaseClubItem({ ...clubItem });
                        setClubItem({ ...clubItem });
                    } catch (e) {
                        console.error("Error deleting codes: ", e);
                    }
                } else {
                    console.log(response);
                }
            });
        }
    }

    function containsPerk(perk: string) {
        return clubItem?.perks.includes(perk);

    }
  
    useEffect(() => {
        getClubData()
    }, [data]);

    useEffect(() => {
        if (clubItem != null && baseClubItem != null) {
            setChangesApplied(!deepEqualCheck(clubItem, baseClubItem));
        }
    }, [clubItem]);

    if (clubItem == null) {
        return <></>;
    } return (
        <>
            <Box pos="relative">
                <LoadingOverlay visible={visibleLoadingScreen}  zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
                {changesApplied ? <>
                    <br></br>
                    <Alert variant="light" color="yellow" title={t('GolfClub.changes.alert.title')} icon={<IconInfoCircle />}>
                        <div style={{ marginTop: '10px' }}> 
                            <Button onClick={saveClubData} color={theme.colors.green[7]} > 
                                {t('GolfClub.changes.save')}
                            </Button>
                        </div>
                    </Alert>
                    <br></br>
                </> : <></>
                }

                <Grid>
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>

                        <Card shadow="sm" padding="lg" radius="md" withBorder>

                            {clubItem.subCourses.length > 1 &&
                             <>
                                <Alert variant="light" color="gray" title={`${t('GolfClub.active.course.title')} ${clubItem.course_name}`} icon={<IconInfoCircle />}>
                                    {t('GolfClub.change.course.alert')}
                                </Alert>
                                <br/>
                            </>
                            }

                            <Card.Section withBorder inheritPadding py="xs">
                                { /* GOLF CLUB NAME */}
                                <Input.Wrapper label={t('GolfClub.Name')}>
                                    <Input
                                        value={clubItem.name}
                                        onChange={(event) => {
                                            const newClubItem = { ...clubItem, name: event.currentTarget.value };
                                            setClubItem(newClubItem);
                                        }}
                                    />
                                </Input.Wrapper>
                                <br></br>
                                { /* GOLF CLUB DESCRIPTION */}
                                <Textarea
                                    placeholder={t('GolfClub.Description')}
                                    label={t('GolfClub.Description')}
                                    autosize
                                    minRows={2}
                                    value={clubItem.description}
                                    onChange={(event) => {
                                        const newClubItem = { ...clubItem, description: event.currentTarget.value };
                                        setClubItem(newClubItem);
                                    }}
                                />
                                <br></br>
                                { /* GOLF CLUB WEBSITE */}
                                <Input.Wrapper label={t('GolfClub.Website')}>
                                    <Input
                                        value={clubItem.website}
                                        onChange={(event) => {
                                            const newClubItem = { ...clubItem, website: event.currentTarget.value };
                                            setClubItem(newClubItem);
                                        }}
                                    />
                                </Input.Wrapper>
                                <br></br>
                                { /* Imprint*/}
                                <Input.Wrapper label={t('GolfClub.Imprint')}>
                                    <Input
                                        value={clubItem.imprint}
                                        onChange={(event) => {
                                            const newClubItem = { ...clubItem, imprint: event.currentTarget.value };
                                            setClubItem(newClubItem);
                                        }}
                                    />
                                </Input.Wrapper>
                                <br />
                                <Divider />
                                <br/>
                                { /* CONTACT PERSON */}
                                <Input.Wrapper label={t('GolfClub.Partner.Name')}>
                                    <Input
                                        value={clubItem.contact_name}
                                        onChange={(event) => {
                                            const newClubItem = { ...clubItem, contact_name: event.currentTarget.value };
                                            setClubItem(newClubItem);
                                        }}
                                    />
                                </Input.Wrapper>
                                <br></br>
                                { /* CONTACT PERSON PHONE */}
                                <Input.Wrapper label={t('GolfClub.Partner.Phone')}>
                                    <Input
                                        value={clubItem.contact_phone}
                                        onChange={(event) => {
                                            const newClubItem = { ...clubItem, contact_phone: event.currentTarget.value };
                                            setClubItem(newClubItem);
                                        }}
                                    />
                                </Input.Wrapper>
                                <br></br>
                            </Card.Section>
                        </Card>

                    </Grid.Col>
                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>

                        <Card shadow="sm" padding="lg" radius="md" withBorder>
                            <Card.Section withBorder inheritPadding py="xs">
                                { /* HOLE COUNT */}
                                <Stack>
                                    <Text fw={500}>{t('GolfClub.Holes')}</Text>
                                    <Divider my={0} />
                                    <Radio
                                        checked={clubItem.holes === 9}
                                        color={theme.colors.green[7]}
                                        onChange={() => {
                                            const newClubItem = { ...clubItem, holes: 9 };
                                            setClubItem(newClubItem);
                                        }}
                                        label="9"
                                    />
                                    <Radio
                                        checked={clubItem.holes === 18}
                                        color={theme.colors.green[7]}
                                        onChange={() => {
                                            const newClubItem = { ...clubItem, holes: 18 };
                                            setClubItem(newClubItem);
                                        }}
                                        label="18"
                                    />
                                </Stack>
                                <br></br>

                                { /* WEEKDAYS/WEEKENDS */}
                                <Stack>
                                    <Text fw={500}>{t('GolfClub.days.usable')}</Text>
                                    <Divider my={0} />
                                    <Radio
                                        checked={clubItem.weekdays === 1}
                                        color={theme.colors.green[7]}
                                        onChange={() => {
                                            const newClubItem = { ...clubItem, weekdays: 1 };
                                            setClubItem(newClubItem);
                                        }}
                                        label="Nur Wochentage"
                                    />
                                    <Radio
                                        checked={clubItem.weekdays === 0}
                                        color={theme.colors.green[7]}
                                        onChange={() => {
                                            const newClubItem = { ...clubItem, weekdays: 0 };
                                            setClubItem(newClubItem);
                                        }}
                                        label="Alle Tage"
                                    />

                                </Stack>
                                <br></br>

                                { /* PERKS */}
                                <Stack>
                                    <Text fw={500}>{t('GolfClub.Perks')}</Text>
                                    <Divider my={0} />
                                    {perks.map((perk) => (
                                       <Checkbox
                                            key={perk}
                                            checked={containsPerk(perk)}
                                            color={theme.colors.green[7]}
                                            label={t(`GolfClub.perk.${perk}`)}
                                            onChange={() => {
                                                const newClubItem = { ...clubItem, perks: clubItem.perks };
                                                newClubItem.perks = toggleItemInArray(perk, newClubItem.perks);
                                                setClubItem(newClubItem);
                                            }}
                                        />
                                    ))}
          
                                </Stack>
                                <br></br>

                            </Card.Section>
                        </Card>

                    </Grid.Col>

                    <Grid.Col span={{ base: 12, md: 6, lg: 4 }}>

                        <Card shadow="sm" padding="lg" radius="md" withBorder>
                            <Card.Section withBorder inheritPadding py="xs">
                                {/* AVAILABILITY */}
                                <Stack>
                                    <Text fw={500}>{t('GolfClub.WeeksClosed')}</Text>
                                    <Divider my={0} />
                                    <Grid>
                                        {[...Array(52)].map((_, i) => (
                                            <Grid.Col span={2} key={i}>
                                                <Box
                                                    onClick={() => {
                                                        const newWeeksClosedData = toggleItemInArray((i + 1).toString(), clubItem.weeks_closed);
                                                        const newClubItem = { ...clubItem, weeks_closed: newWeeksClosedData };
                                                        setClubItem(newClubItem);
                                                    }}
                                                    style={{
                                                        backgroundColor: clubItem.weeks_closed.includes((i + 1).toString()) ? theme.colors.brand[7] : 'transparent',
                                                        color: clubItem.weeks_closed.includes((i + 1).toString()) ? 'white' : 'black',
                                                        textAlign: 'center',
                                                        cursor: 'pointer',
                                                        borderRadius: '2px',
                                                    }}
                                                >
                                                    {i + 1}
                                                </Box>
                                            </Grid.Col>
                                        ))}
                                    </Grid>
                                </Stack>
                                <br />
                            </Card.Section>
                        </Card>
                        <br/>
                        <Card shadow="sm" padding="lg" radius="md" withBorder>
                            <Card.Section withBorder inheritPadding py="xs">
                                {/* HANDICAP */}
                                <Stack>
                                    <Text fw={500}>{t('GolfClub.Handicap.Title')}</Text>
                                    <Divider my={0} />
                                    { /* CONTACT PERSON */}
                                    <Input.Wrapper label={t('GolfClub.Handicap.Men')}>
                                        <Input
                                            value={clubItem.handicap_m}
                                            onChange={(event) => {
                                                const newClubItem = { ...clubItem, handicap_m: event.currentTarget.value };
                                                setClubItem(newClubItem);
                                            }}
                                        />
                                    </Input.Wrapper>
                                    { /* CONTACT PERSON PHONE */}
                                    <Input.Wrapper label={t('GolfClub.Handicap.Women')}>
                                        <Input
                                            value={clubItem.handicap_f}
                                            onChange={(event) => {
                                                const newClubItem = { ...clubItem, handicap_f: event.currentTarget.value };
                                                setClubItem(newClubItem);
                                            }}
                                        />
                                    </Input.Wrapper>
                                </Stack>
                                <br />
                            </Card.Section>
                        </Card>

                    </Grid.Col>
                </Grid>
            </Box>
            
        </>
    );
}
export default GolfClub;