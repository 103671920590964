import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ActionIcon, Center, Stack, Grid, Card, Image, Button, Text, AspectRatio, Group, useMantineTheme } from '@mantine/core';
import GalleryDropzone from './GalleryDropzone'
import { ClubFBItem } from './../../../model/ClubFBItem';
import { useFetchClubImages } from './../../../hooks/useFetchClubImages';
import { restAPIClient } from './../../../api/Client';
import { IconTrash, IconInfoSquare} from '@tabler/icons-react';

function Gallery({ data }: { data: ClubFBItem }) {
    const { t } = useTranslation();
    const theme = useMantineTheme();
    const [fetchAttempt, setFetchAttempt] = useState(1);
    const images = useFetchClubImages(data, fetchAttempt);

    const uploadNewImage = (url: string) => {
        const token: any = data.ngaKey;
        restAPIClient.post(`/course_images.php?course_id=${data.courseId}`, {
            prio: Array.isArray(images) && images.length > 0 ? 0 : 1,
            url: url.split("&token")[0]
        }, {
            headers: {
                'X-Auth-Key': token
            }
        }).then((response) => {
            if (response.status === 200) {
                try {
                    const ngaStatus = response.data["status"];
                    if (ngaStatus === "200") {
                        setFetchAttempt(fetchAttempt + 1);;
                    } else {
                        console.log("Error adding image", response);
                    }
                } catch (e) {
                    console.error("Error deleting codes: ", e);
                }
            } else {
                console.log(response);
            }
        });
    }

    const handleSetAsMain = (id: string) => {
        const token: any = data.ngaKey;
        restAPIClient.put(`/course_images.php?&course_id=${data.courseId}&favorite_id=${id}`, {}, {
            headers: {
                'X-Auth-Key': token
            }
        }).then((response) => {
            if (response.status === 200) {
                try {
                    setFetchAttempt(fetchAttempt + 1);
                } catch (e) {
                    console.error("Error deleting photo: ", e);
                }
            } else {
                console.log(response);
            }
        });
    };

    const handleDeleteImage = (id: string) => {
        const confirmed = window.confirm("Are you sure you want to delete this item?");
        if (confirmed) {
            const token: any = data.ngaKey;
            restAPIClient.delete(`/course_images.php?&course_id=${data.courseId}&id=${id}`, {
                headers: {
                    'X-Auth-Key': token
                }
            }).then((response) => {
                if (response.status === 200) {
                    try {
                        setFetchAttempt(fetchAttempt + 1);
                    } catch (e) {
                        console.error("Error deleting photo: ", e);
                    }
                } else {
                    console.log(response);
                }
            });
        } 
    };

    return (
        <Grid>
            <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                <Card shadow="sm" padding="lg" radius="md" withBorder>
                    {images.length < 9 ?
                        <Card.Section withBorder inheritPadding py="xs">
                            <GalleryDropzone data={data} uploadImageEntry={uploadNewImage} />
                        </Card.Section>
                        : <></>
                    }
                 

                    {/* Gallery Section */}
                    <Card.Section withBorder inheritPadding py="xs" mt="lg">
                        <Center>
                            <Stack align="center">
                                <Text size="lg" fw={600}>
                                    {t('gallery.title')}
                                </Text>
                                <Text size="sm" style={{ color: '#666'}}>
                                    {t('gallery.subtitle')}
                                </Text>
                            </Stack>
                        </Center>
                        <br></br>
                        {images.length > 0 ?
                            <Grid>
                                {images.map((image) => (
                                    <Grid.Col key={image.id} span={{ base: 12, md: 6, lg: 4 }}>
                                        <Card shadow="sm" radius="md" withBorder style={{ position: 'relative', overflow: 'hidden', padding: '0px' }}>
                                            {/* Image in 16:9 Aspect Ratio, stretched and center cropped */}
                                            <AspectRatio ratio={4 / 3}>
                                                <Image
                                                    src={image.url}
                                                    alt={`Image ${image.id}`}
                                                    fit="cover"   // Ensures the image fills the container while maintaining aspect ratio
                                                    style={{ width: '100%', height: '100%', objectPosition: 'center' }} // Ensures center cropping
                                                />
                                            </AspectRatio>

                                            {/* Buttons positioned on top of the image */}
                                            <Group
                                                style={{
                                                    position: 'absolute',
                                                    bottom: 0,
                                                    left: 0,
                                                    right: 0,
                                                    justifyContent: 'center',
                                                    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Slight background to improve button visibility
                                                    padding: '10px',
                                                    borderRadius: '0 0 10px 10px', // Rounded corners only at the bottom
                                                }}
                                            >
                                                {image.prio === 0 ?
                                                    <Button
                                                        size="xs"
                                                        color={image.prio === 0 ? theme.colors.green[6] : 'blue'}
                                                        onClick={() => handleSetAsMain(image.id)}
                                                    >
                                                        {t('gallery.action.favorite')}
                                                    </Button>
                                                    :
                                                    <Text size="xs" style={{ color: 'white' }}>
                                                        {t('gallery.marked.as.favorite')}
                                                    </Text>
                                                }
                                                <ActionIcon variant="filled" color="red" aria-label="Settings" onClick={() => { handleDeleteImage(image.id) }}>
                                                    <IconTrash style={{ width: '70%', height: '70%' }} stroke={1.5} />
                                                </ActionIcon>
                                            </Group>
                                        </Card>
                                    </Grid.Col>
                                ))}
                            </Grid> 
                            : 
                            <Center style={{ height: '30vh' }}>
                                <Stack align="center">
                                    <IconInfoSquare size={36} color={theme.colors.green[8]} />
                                    <Text size="sm" style={{ marginTop: '5px', color: '#666' }}>
                                        {t('species.tabs.none')}
                                    </Text>
                                </Stack>
                            </Center>
                        }
                        <br></br>
                    </Card.Section>
                </Card>
            </Grid.Col>
        </Grid>
    );
 
}

export default Gallery;
