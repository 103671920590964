import { useDisclosure } from '@mantine/hooks';
import { useState} from 'react';
import { ClubFBItem } from './../../../model/ClubFBItem';
import { restAPIClient } from './../../../api/Client';
import { ActionIcon, Center, Divider, Group, Image, Modal, Pagination, Stack, SimpleGrid, Card, Text, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { ClubFactItem } from '../../../model/ClubFactItem';
import { IconSeedingFilled, IconTrash, IconStar, IconStarFilled, IconGolf, IconLetterCase } from '@tabler/icons-react';
import { EcoFactsHoleEditor } from './EcoFactsHoleEditor';
import { EcoFactsEditor } from './EcoFactsEditor';

interface EcoFactsGridProps {
    facts: ClubFactItem[];
    clubItem: ClubFBItem,
    fetchData: () => void;
}

export function EcoFactsGrid({ facts, clubItem, fetchData}: EcoFactsGridProps) {

    const { t } = useTranslation();
    const theme = useMantineTheme();
    const [opened, { open, close }] = useDisclosure(false);
    const [activePage, setActivePage] = useState(1);
    const [factEditing, setFactEditing] = useState<ClubFactItem>();
    const [editingText, setEditingText] = useState(false);
    const itemsPerPage = 3;

    const totalPages = Math.ceil(facts.length / itemsPerPage);
    const startIndex = (activePage - 1) * itemsPerPage;
    const currentFacts = facts.slice(startIndex, startIndex + itemsPerPage);


    const arrayToCommaSeparatedString = (arr: string[]): string => {
        return arr.join(", ");
    };


    const editFactText = (fact: ClubFactItem) => {
        setEditingText(true);
        setFactEditing(fact);
        open();
    }

    //edit fact holes
    const editFactsHoles = (fact: ClubFactItem) => {
        setEditingText(false);
        setFactEditing(fact);
        open();
    }


    //toggle featured
    const toggleFeatured = (fact: ClubFactItem) => {
        if (fact.featured === 1) {
            fact.featured = 0;
        } else {
            fact.featured = 1;
        }
        updateFact(fact);
    }


    //update fact
    const updateFact = (fact: ClubFactItem) => {
        close();
        const token: any = clubItem.ngaKey;
        restAPIClient.put(`/course_facts.php?&course_id=${clubItem.courseId}`, {
            id: fact.id,
            title: fact.title,
            description: fact.description,
            holes: arrayToCommaSeparatedString(fact.holes),
            featured: fact.featured
        }, {
            headers: {
                'X-Auth-Key': token
            }
        }).then((response) => {
            if (response.status === 200) {
                try {
                    fetchData();
                } catch (e) {
                    console.error("Error deleting codes: ", e);
                }
            } else {
                console.log(response);
            }
        });
    }

    //update fact
    const deleteFact = (fact: ClubFactItem) => {
        const confirmed = window.confirm(t('ecofacts.delete.confirmation'));
        if (confirmed) {
            const token: any = clubItem.ngaKey;
            restAPIClient.delete(`/course_facts.php?&course_id=${clubItem.courseId}&fact_id=${fact.id}`, {
                headers: {
                    'X-Auth-Key': token
                }
            }).then((response) => {
                if (response.status === 200) {
                    try {
                        fetchData();
                    } catch (e) {
                        console.error("Error deleting codes: ", e);
                    }
                } else {
                    console.log(response);
                }
            });
        } 
    }

    //helper function to sort hole numbers in ASC order
    function sortStringNumbersSafely(arr: string[]): string[] {
        return [...arr].sort((a, b) => {
            const numA = Number(a);
            const numB = Number(b);
            if (isNaN(numA) && isNaN(numB)) return 0;
            if (isNaN(numA)) return 1;
            if (isNaN(numB)) return -1;
            return numA - numB;
        });
    }

    //helper function to format post date
    function formatPostDate(dateStr: string): string {
        const date = new Date(dateStr.replace(" ", "T")); // Convert to ISO format
        const options = {
            year: date.getFullYear(),
            month: date.toLocaleString('default', { month: 'long' }),
            day: date.getDate(),
            hour: date.getHours() % 12 || 12,  // Convert to 12-hour format
            minute: String(date.getMinutes()).padStart(2, '0'),
        };

        const formattedDate = `${options.day}. ${options.month} ${options.year} ${options.hour}:${options.minute}`;
        //const formattedDate = `${options.month} ${options.day}, ${options.year} ${options.hour}:${options.minute}:${options.second} ${options.period}`;
        return formattedDate;
    }

    const factCards = currentFacts.map((fact) => (
        <Card key={fact.id} shadow="sm" padding="lg" radius="md" withBorder>
            {fact.image &&
                <Card.Section>
                    <Image src={fact.image} alt={fact.title} height={'100%'} />
                </Card.Section>
            }
            <Group mt="md" mb="xs">
                <Text fw={500}>{fact.title}</Text>
                <Text size="xs" color="dimmed" style={{ fontStyle: 'italic' }}>{formatPostDate(fact.created_at)}</Text>
            </Group>

            <Text size="sm" color="dimmed">
                {fact.description}
            </Text>

            <Divider my="sm" />

            {fact.holes.length > 0 && (
                <Text size="xs" color="dimmed">
                    {t('species.grid.holes.available')}: {arrayToCommaSeparatedString(sortStringNumbersSafely(fact.holes))}
                </Text>
            )}

            <Group mt="md">
                <ActionIcon variant="filled" color="green" onClick={() => editFactText(fact)}>
                    <IconLetterCase style={{ width: '70%', height: '70%' }} stroke={1.5} />
                </ActionIcon>
                <ActionIcon variant="filled" color="green" onClick={() => editFactsHoles(fact)}>
                    <IconGolf style={{ width: '70%', height: '70%' }} stroke={1.5} />
                </ActionIcon>
                <ActionIcon variant="filled" color="green" onClick={() => toggleFeatured(fact)}>
                    {fact.featured === 1 ?
                        <IconStarFilled style={{ width: '70%', height: '70%' }} stroke={1.5} /> :
                        <IconStar style={{ width: '70%', height: '70%' }} stroke={1.5} />
                    }
                </ActionIcon>
                <ActionIcon variant="filled" color="red" onClick={() => { deleteFact(fact) }}>
                    <IconTrash style={{ width: '70%', height: '70%' }} stroke={1.5} />
                </ActionIcon>
            </Group>
        </Card>
    ));

    const editFactsTitle = editingText ? 'ecofacts.text.edit' : 'species.holes.edit.title'

    return (
        <>
            <Modal opened={opened} onClose={close} title={t(editFactsTitle)}>
                {factEditing &&
                    <>
                        {editingText ?
                            <EcoFactsEditor fact={factEditing} updateFact={updateFact} />
                            :
                            <EcoFactsHoleEditor fact={factEditing} updateFact={updateFact} />
                        }
                    </>
                }
            </Modal>

            {facts.length ? (
                <>
                    <SimpleGrid cols={{ base: 1, sm: 1, md: 1, lg: 2, xl: 3 }}>
                        {factCards}
                    </SimpleGrid>
                    <br />
                    <Pagination
                        total={totalPages}
                        value={activePage}
                        onChange={setActivePage}
                        siblings={1}
                        color="green"
                    />
                </>
            ) : (
                <Center style={{ height: '30vh' }}>
                    <Stack align="center">
                        <IconSeedingFilled size={36} color={theme.colors.green[8]} />
                        <Text size="sm" color="dimmed" ta="center" style={{ marginTop: '5px' }}>
                            {t('ecofacts.no.posts')}
                        </Text>

                    </Stack>
                </Center>
            )}
        </>
    );

}


