import { useEffect, useState } from 'react';
import { Button, Text, useMantineTheme } from '@mantine/core';
import { HoleSelection } from './../Species/HoleSelection';
import { ClubFactItem } from '../../../model/ClubFactItem';
import { useTranslation } from 'react-i18next';

interface EcoFactsHoleEditorProps {
    fact: ClubFactItem;
    updateFact: (fact: ClubFactItem) => void;
}

export function EcoFactsHoleEditor({ fact, updateFact }: EcoFactsHoleEditorProps) {

    const [holes, setHoles] = useState<string[]>([]);
    const { t } = useTranslation();
    const theme = useMantineTheme();

    useEffect(() => {
        setHoles(fact.holes);
    }, [fact.holes])

    return (
        <>
            <Text size="sm" style={{ marginTop: '5px', color: '#666' }}>
                {t('ecofacts.new.holes')}
            </Text>
            <br />
            <HoleSelection
                selectedHoles={holes}
                setSelectedHoles={setHoles}
            />
            <br />
            <br />
            <Button
                onClick={() => {
                    fact.holes = holes;
                    updateFact(fact);
                }}
                style={{ backgroundColor: theme.colors.green[7] }}>
                {t('species.holes.edit.save')}
            </Button>
        </>
    );
}

export default EcoFactsHoleEditor;