import { useEffect, useState } from 'react';
import { Button, Text, useMantineTheme } from '@mantine/core';
import { HoleSelection } from './HoleSelection';
import { SpeciesItem } from '../../../model/SpeciesItem';
import { useTranslation } from 'react-i18next';

interface HoleEditorProps {
    speciesEditing: SpeciesItem;
    updateSpecies: (species: SpeciesItem) => void;
}

export function HoleEditor({ speciesEditing, updateSpecies }: HoleEditorProps) {

    const [holes, setHoles] = useState<string[]>([]);
    const { t } = useTranslation();
    const theme = useMantineTheme();

    useEffect(() => {
        setHoles(speciesEditing.holes);
    }, [speciesEditing.holes])

    return (
        <>
            <Text size="sm" style={{ marginTop: '5px', color: '#666' }}>
                {t('species.add.holes.info')}
            </Text>
            <br/>
            <HoleSelection
                selectedHoles={holes}
                setSelectedHoles={setHoles}
            />
            <br />
            <br />
            <Button
                onClick={() => {
                    speciesEditing.holes = holes;
                    updateSpecies(speciesEditing) 
                }}
                style={{ backgroundColor: theme.colors.green[7] }}>
                {t('species.holes.edit.save')}
            </Button>
        </>
    );
}

export default HoleEditor;