import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Card, useMantineTheme } from '@mantine/core';
import { ClubFBItem } from './../../../model/ClubFBItem';
import { useFetchClubFacts } from './../../../hooks/useFetchClubFacts';
import { EcoFactsForm } from './EcoFactsForm';
import { EcoFactsGrid } from './EcoFactsGrid';

function EcoFacts({ data }: { data: ClubFBItem }) {

    const { t } = useTranslation();
    const theme = useMantineTheme();
    const [fetchAttempt, setFetchAttempt] = useState(1);
    const facts = useFetchClubFacts(data, fetchAttempt);

    const refetchData = () => {
        setFetchAttempt(fetchAttempt + 1)
    }

    return (
        <Grid>
            <Grid.Col span={{ base: 12, md: 4, lg: 3 }}>
                <Card shadow="sm" padding="lg" radius="md" withBorder>
                    <Card.Section withBorder inheritPadding py="xs" style={{ textAlign: 'center' }}>
                        <EcoFactsForm data={data} fetchData={refetchData} />
                    </Card.Section>
                </Card>
            </Grid.Col>
            <Grid.Col span={{ base: 12, md: 8, lg: 9 }}>
                <Card shadow="sm" padding="lg" radius="md" withBorder>
                    <Card.Section withBorder inheritPadding py="xs">
                        <EcoFactsGrid facts={facts} clubItem={data} fetchData={refetchData} />
                    </Card.Section>        
                </Card>
            </Grid.Col>
        </Grid>
    );
}

export default EcoFacts;
