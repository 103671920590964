import { Skeleton } from '@mantine/core';

export function SkeletonLayout() {
    return (
        <>
            {Array(15)
                .fill(0)
                .map((_, index) => (
                    <Skeleton key={index} h={28} mt="sm" animate={false} />
                ))}
        </>
    );
}