import { Chip, Flex, useMantineTheme } from '@mantine/core';

interface HoleSelectionProps {
    selectedHoles: string[];
    setSelectedHoles: (holes: string[]) => void;
}

export function HoleSelection({ selectedHoles, setSelectedHoles }: HoleSelectionProps) {

    const theme = useMantineTheme();

    const holes = Array.from({ length: 18 }, (_, i) => (i + 1).toString());

    return (
        <Flex
            direction="column" 
            justify="center"   
            align="center"      
        >
            <Chip.Group value={selectedHoles} onChange={setSelectedHoles} multiple>
                <Flex wrap="wrap" gap="sm" justify="center">
                    {holes.map((hole) => (
                        <Chip key={hole} value={hole} color={theme.colors.green[6]}>
                            {hole}
                        </Chip>
                    ))}
                </Flex>
            </Chip.Group>
        </Flex>
    );
}

export default HoleSelection;