import { useEffect, useState } from "react"
import { GroupItem } from './../model/SpeciesSearchItem';
import { ClubFBItem } from './../model/ClubFBItem';
//import { restAPIClient } from './../api/Client';
import speciesSearchData from './../assets/searchable-species-v1.json';

export function useFetchSpeciesSearchResults(data: ClubFBItem) {

    const [response, setResponse] = useState<GroupItem[]>([]);

    useEffect(() => {
        const fetchItems = async () => {
            try {
                const groups = speciesSearchData["groups"] as GroupItem[];
                setResponse(groups);
            } catch (e) {
                console.error("Error loading species search data: ", e);
            }
            /*const token: any = data.ngaKey;
            restAPIClient.get('species_search.php', {
                headers: {
                    'X-Auth-Key': token
                }
            }).then((response) => {
                if (response.status === 200) {
                    try {
                        const ngaStatus = response.data["status"];
                        if (ngaStatus === "200") {
                            const groups = response.data["groups"] as GroupItem[];
                            setResponse(groups);
                        }
                    } catch (e) {
                        console.error("Error fetching codes: ", e);
                    }
                } else {
                    console.log(response);
                }
            });*/

        }
        fetchItems();
    }, [data]);

    return response;
}