import { ClubFBItem } from './../../../model/ClubFBItem';
import { useDisclosure } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { restAPIClient } from './../../../api/Client';
import { useFetchSpeciesSearchResults } from './../../../hooks/useFetchSpeciesSearchResults';
import { useFetchClubSpecies } from './../../../hooks/useFetchClubSpecies';
import { Button, Card, Grid, Modal, Tabs, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'react-i18next';
import { SpeciesSearchItem } from '../../../model/SpeciesSearchItem';
import { SpeciesGrid, arrayToCommaSeparatedString } from './SpeciesGrid';
import { SpeciesCatalog } from './SpeciesCatalog';


function Species({ data }: { data: ClubFBItem }) {

    const { t } = useTranslation();
    const theme = useMantineTheme();
    const [opened, { open, close }] = useDisclosure(false);
    const [fetchAttempt, setFetchAttempt] = useState(1);
    const [selectedItem, setSelectedItem] = useState<SpeciesSearchItem | null>(null);
    const [selectedItemIsHightlight, setSelectedItemIsHighlight] = useState(false);
    const [catalogValue, setCatalogValue] = useState('');
    const [selectedHoles, setSelectedHoles] = useState<string[]>([]);
    const species = useFetchClubSpecies(data, fetchAttempt);
    const groups = useFetchSpeciesSearchResults(data);

    useEffect(() => {
        if (!opened) {
            handleClear();
        }
    }, [opened]);

    // Flatten the data for easier lookup
    const flattenedData: SpeciesSearchItem[] = groups
        .map((group) => group.species)
        .flat();


    // Remove duplicates from species list (based on the species name)
    const uniqueSpecies = Array.from(
        new Map(flattenedData.map((item) => [item.name, item])).values()
    );


    // Handler function to manage selected value
    const handleItemSelect = (value: string) => {
        setCatalogValue(value);
        const selected = uniqueSpecies.find((item) => item.name === value) || null;
        setSelectedItem(selected);
    };

    // Save species
    //TODO add highlight property to api and here
    const saveSpecies = () => {
        close();
        const token: any = data.ngaKey;
        restAPIClient.post(`/species.php?course_id=${data.courseId}`, {
            species_id: selectedItem?.species,
            course_id: data.courseId,
            holes: arrayToCommaSeparatedString(selectedHoles),
            featured: selectedItemIsHightlight
        }, {
            headers: {
                'X-Auth-Key': token
            }
        }).then((response) => {
            if (response.status === 200) {
                try {
                    const ngaStatus = response.data["status"];
                    if (ngaStatus === "200") {
                        handleClear();
                        refetchData();
                    } else {
                        console.log(response);
                    }
                } catch (e) {
                    console.error("Error adding species: ", e);
                }
            } else {
                console.log(response);
            }
        });
    }

    const refetchData = () => {
        setFetchAttempt(fetchAttempt + 1);
    }

    const handleClear = () => {
        setSelectedItem(null);
        setCatalogValue('');
        setSelectedHoles([]);
        setSelectedItemIsHighlight(false);
    };

    return (
        <>
            <Modal opened={opened} onClose={close} title="" >
                <SpeciesCatalog
                    catalogValue={catalogValue}
                    selectedItem={selectedItem}
                    isHighlight={selectedItemIsHightlight}
                    setIsHighlight={setSelectedItemIsHighlight}
                    selectedHoles={selectedHoles}
                    setSelectedHoles={setSelectedHoles}
                    groups={groups}
                    handleItemSelect={handleItemSelect}
                    saveSpecies={saveSpecies}
                />
            </Modal>
            <Grid>
                <Grid.Col span={{ base: 12, md: 12, lg: 12 }}>
                    <Card shadow="sm" padding="lg" radius="md" withBorder>
                        <Card.Section withBorder inheritPadding py="xs">
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div></div>
                                <Button onClick={open} style={{ backgroundColor: theme.colors.green[7] }}>
                                    {t('species.add.new')}
                                </Button>
                            </div>
                            <Tabs defaultValue="All" color={theme.colors.green[6]}>
                                <Tabs.List>
                                    <Tabs.Tab value="Highlights" >
                                        Highlights
                                    </Tabs.Tab>
                                    <Tabs.Tab value="All" >
                                        Alle
                                    </Tabs.Tab>
                                    {groups.map((item) => (
                                        <Tabs.Tab value={item.name} key={item.id} >
                                            {t(`species.groups.${item.name}`) }
                                        </Tabs.Tab>
                                    ))}
                                </Tabs.List>
                                <Tabs.Panel value="Highlights" >
                                    <SpeciesGrid
                                        species={species.filter(spec => spec.featured === "1")}
                                        defaultText={t('species.tabs.none.highlights.tab')}
                                        clubItem={data}
                                        fetchData={refetchData}
                                        openSpeciesCatalog={open}
                                    />
                                </Tabs.Panel>
                                <Tabs.Panel value="All" >
                                    <SpeciesGrid
                                        species={species}
                                        defaultText={t('species.tabs.none.all.tab')}
                                        clubItem={data}
                                        fetchData={refetchData}
                                        openSpeciesCatalog={open}
                                    />
                                </Tabs.Panel>
                                {groups.map((item) => (
                                    <Tabs.Panel value={item.name} key={item.name} >
                                        <SpeciesGrid
                                            species={species.filter(spec => spec.group_id === item.id)}
                                            defaultText={t('species.tabs.none')}
                                            clubItem={data}
                                            fetchData={refetchData}
                                            openSpeciesCatalog={open}
                                        />
                                    </Tabs.Panel>
                                ))}
                            </Tabs>
                        </Card.Section>        
                    </Card>
                </Grid.Col>
            </Grid>
        </>
    );
}

export default Species;
