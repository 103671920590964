import { useCallback, useState } from 'react';
import { doc, setDoc } from "firebase/firestore"; 
import { TextInput, Button, Paper, Title, Group, Stack, Text, Center, Box, useMantineTheme } from '@mantine/core';
import logo from './../../assets/logo_sm.png';
import { restAPIClient } from './../../api/Client';
import { useAuthState, db } from './../../firebase';
import TermsAndConditions from './TermsAndConditions'
import { useTranslation } from 'react-i18next';


export function ActivateCourse({ retryActivation }) {

    const { user } = useAuthState();
    const theme = useMantineTheme();
    const { t } = useTranslation();
    const [termsAgreed, setTermsAgreed] = useState(false);

    const saveClubItem = async (clubItem) => {
        try {
            await setDoc(doc(db, "clubs", user.uid), clubItem);
            retryActivation();
        } catch (e) {
            console.error("Error adding document: ", e);
        }
    };

    const handleSubmit = useCallback(async e => {
        e.preventDefault();
        const { activationId } = e.target.elements;
        restAPIClient.get(`/admin_create.php?k=${activationId.value}`).then((response) => {
            console.log(response.status);
            if (response.status === 200) {
                try {
                    const ngaStatus = response.data["status"];
                    if (ngaStatus === 200) {
                        const clubItem = {
                            ngaKey: response.data["ngaKey"],
                            courseId: response.data["courseId"],
                            ownerId: user.uid,
                            subCourses: response.data["subCourses"],
                            language: "de",
                        }
                        saveClubItem(clubItem);
                    }
                } catch (e) {
                    console.error("Error adding document: ", e);
                }
            }
        })

    }, []);


    return (
        <Center style={{ height: '80vh' }}>
            <Box style={{ width: 400 }}>
                <Paper radius="md" p="xl" withBorder shadow="md">
                    <Center>
                        <img src={logo} alt="Logo" width={100} />
                    </Center>
                    <Title order={2} align="center" mt="md" mb="lg">
                        {t('activation.title')}
                    </Title>
                    <Text size="sm" align="center" style={{ marginBottom: '12px', color: '#666' }}>
                        {t('activation.id.explained')}
                    </Text>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing="md">
                            <TextInput
                                name="activationId"
                                label={t('activation.id.title')}
                                placeholder="Activation Code"
                                type="text"
                                required
                            />
                            <TermsAndConditions setAgreed={() => { setTermsAgreed(true) }} />
                            <Group position="apart">
                                <Button type="submit" disabled={!termsAgreed} fullWidth style={{ backgroundColor: theme.colors.green[7] }}>
                                    {t('activation.submit')}
                                </Button>
                            </Group>
                        </Stack>
                    </form>
                </Paper>
            </Box>
        </Center>
    );
};
