import React from 'react';
import { ClubItem, ClubFBItem } from './../../../model/ClubFBItem';

function AdditonalSettings({ data }: { data: ClubFBItem }) {
    return (
        <div className="App">
            <p>Welcome to extra stuff..</p>
        </div>
    );
}

export default AdditonalSettings;
