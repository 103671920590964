import { IconKey, IconUsers, IconQrcode, IconAppWindow, IconCircleDashedCheck } from '@tabler/icons-react';
import { MantineTheme } from '@mantine/core';

export function getSteps(theme: MantineTheme) {
    const steps = [
        {
            icon: <IconKey size={32} color={theme.colors.green[6]} />,
            title: "employeees.points.1.header",
            description: "employeees.points.1.text"
        },
        {
            icon: <IconUsers size={32} color={theme.colors.green[6]} />,
            title: "employeees.points.2.header",
            description: "employeees.points.2.text"
        },
        {
            icon: <IconQrcode size={32} color={theme.colors.green[6]} />,
            title: "employeees.points.3.header",
            description: "employeees.points.3.text"
        },
        {
            icon: <IconAppWindow size={32} color={theme.colors.green[6]} />,
            title: "employeees.points.4.header",
            description: "employeees.points.4.text"
        },
        {
            icon: <IconCircleDashedCheck size={32} color={theme.colors.green[6]} />,
            title: "employeees.points.5.header",
            description: "employeees.points.5.text",
        }
    ];
    return steps
}

export const faqs = [
    {
        question: "Was ist der Zweck eines Mitarbeitercodes?",
        answer: "The employee code is used to authenticate and validate customer QR codes at the point of service."
    },
    {
        question: "Wie erhalten Mitarbeiter ihren Code?",
        answer: "Employees will receive their unique codes via email or directly through the management system."
    },
    {
        question: "employees.faq.tmp",
        answer: "If a code is invalid, employees will receive a notification, and they can verify with the management team."
    },
    {
        question: "Kann ein Code wiederverwendet werden?",
        answer: "No, each code is unique and can only be used once for authentication."
    },
];