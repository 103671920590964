import React, { useState, useRef } from 'react';
import { Button, Modal, ScrollArea, Text, useMantineTheme } from '@mantine/core';
import { useTranslation } from 'react-i18next';

interface TermsAndConditionsProps {
    setAgreed: () => void;
}

const TermsAndConditions = ({ setAgreed }: TermsAndConditionsProps) => {

    const { t } = useTranslation();
    const theme = useMantineTheme();
    const [opened, setOpened] = useState(false);
    const [agreeDisabled, setAgreeDisabled] = useState(true);
    const scrollRef = useRef<HTMLDivElement>(null);

    const termsText = `
    [Insert long Terms and Conditions here]
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla volutpat erat sed dictum congue. 
    Mauris laoreet turpis lorem, ut fringilla turpis rhoncus a. Pellentesque habitant morbi tristique 
    senectus et netus et malesuada fames ac turpis egestas. Donec interdum interdum erat, sed dictum ex 
    mollis nec. Integer at velit odio. Integer a vehicula mauris. Nulla vehicula fermentum risus id 
    sollicitudin. Sed ac gravida nunc. Mauris ultrices placerat tempor. Praesent sed purus ligula.
    
    [Add more content if necessary to make it scrollable]

    [Insert long Terms and Conditions here]
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla volutpat erat sed dictum congue.
    Mauris laoreet turpis lorem, ut fringilla turpis rhoncus a. Pellentesque habitant morbi tristique
    senectus et netus et malesuada fames ac turpis egestas. Donec interdum interdum erat, sed dictum ex
    mollis nec. Integer at velit odio. Integer a vehicula mauris. Nulla vehicula fermentum risus id
    sollicitudin. Sed ac gravida nunc. Mauris ultrices placerat tempor. Praesent sed purus ligula.

    [Add more content if necessary to make it scrollable]

    [Insert long Terms and Conditions here]
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla volutpat erat sed dictum congue.
    Mauris laoreet turpis lorem, ut fringilla turpis rhoncus a. Pellentesque habitant morbi tristique
    senectus et netus et malesuada fames ac turpis egestas. Donec interdum interdum erat, sed dictum ex
    mollis nec. Integer at velit odio. Integer a vehicula mauris. Nulla vehicula fermentum risus id
    sollicitudin. Sed ac gravida nunc. Mauris ultrices placerat tempor. Praesent sed purus ligula.

    [Add more content if necessary to make it scrollable]

    [Insert long Terms and Conditions here]
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla volutpat erat sed dictum congue.
    Mauris laoreet turpis lorem, ut fringilla turpis rhoncus a. Pellentesque habitant morbi tristique
    senectus et netus et malesuada fames ac turpis egestas. Donec interdum interdum erat, sed dictum ex
    mollis nec. Integer at velit odio. Integer a vehicula mauris. Nulla vehicula fermentum risus id
    sollicitudin. Sed ac gravida nunc. Mauris ultrices placerat tempor. Praesent sed purus ligula.

    [Add more content if necessary to make it scrollable]
  `;

    // Check if user has scrolled to the bottom
    const handleScroll = () => {
        const scrollEl = scrollRef.current;
        if (scrollEl) {
            const { scrollTop, scrollHeight, clientHeight } = scrollEl;
            // Enable the "Agree" button if user scrolls to the bottom
            if (scrollTop + clientHeight >= scrollHeight) {
                setAgreeDisabled(false);
            }
        }
    };

    return (
        <>
            <Button onClick={() => setOpened(true)} style={{backgroundColor: theme.colors.green[7]} }>{t('activation.terms.conditions.open.title')}</Button>

            <Modal
                opened={opened}
                onClose={() => setOpened(false)}
                title={t('activation.terms.conditions.title')}
                size="lg"
            >
                <ScrollArea
                    style={{ height: 250 }} // Adjust height based on your preference
                    onScrollCapture={handleScroll} // Handle scroll event
                    ref={scrollRef}
                >
                    <Text>{termsText}</Text>
                </ScrollArea>

                <Button
                    fullWidth
                    mt="md"
                    style={{ backgroundColor: theme.colors.green[7] }}
                    onClick={() => {
                        setAgreed();
                        setOpened(false);
                    }}
                    disabled={agreeDisabled}
                >
                    {t('activation.terms.conditions.agree')}
                </Button>
            </Modal>
        </>
    );
};

export default TermsAndConditions;
