import React, { useRef } from 'react';
import { useState } from 'react';
import { Alert, Button, Text, Progress, useMantineTheme } from '@mantine/core';
import { ClubFBItem } from './../../../model/ClubFBItem';
import { useTranslation } from 'react-i18next';
import ReactCrop, { type Crop } from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import { makeAspectCrop, centerCrop,  } from 'react-image-crop'
import { storage } from './../../../firebase.js';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage'
import { IconInfoCircle } from '@tabler/icons-react';

const { v4: uuidv4 } = require('uuid');

interface CropperProps {
    src: any,
    data: ClubFBItem,
    folder: string,
    imageSaved: (downloadUrl: string) => void;
}


function CropperModal({ src, data, folder, imageSaved }: CropperProps) {

    const { t } = useTranslation();
    const theme = useMantineTheme();

    const [crop, setCrop] = useState<Crop>()
    const [cropAdjusted, setCropAdjusted] = useState(false);
    const [showProgress, setShowProgress] = useState(false);
    //const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const imgRef = useRef<HTMLImageElement>(null);  // For referencing the original image


    const onSubmit = () => {
        //setShowImage(true);

        if (imgRef.current && crop) {
            const croppedImg = getCroppedImg(imgRef.current, crop);
            if (croppedImg) {
                //setCroppedImageUrl(croppedImg);
                setShowProgress(true);
                const imageRef = ref(storage, `${folder}/${uuidv4()}.jpg`);
                const croppedImgBlob = base64ToBlob(croppedImg);
                const uploadTask = uploadBytesResumable(imageRef, croppedImgBlob);
                uploadTask.on(
                    "state_changed",
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        console.log(`Upload is ${progress}% done`);
                        setUploadProgress(progress);
                    },
                    (error) => {
                        console.error("Upload failed", error);
                    },
                    async () => {
                        try {
                            const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
                            imageSaved(downloadURL);
                        } catch (error) {
                            console.error("Error getting download URL", error);
                        }
                    }
                );
            }
        }
    };

    function onImageLoad() {
        if (imgRef.current) {
            const { naturalWidth: width, naturalHeight: height } = imgRef.current;

            const crop = centerCrop(
                makeAspectCrop(
                    {
                        unit: '%',
                        width: 90,
                    },
                    4 / 3,
                    width,
                    height
                ),
                width,
                height
            );
            setCrop(crop);
        }
    }


    // Helper function to convert Base64 image to a Blob
    const base64ToBlob = (base64Data: string): Blob => {
        const byteString = atob(base64Data.split(',')[1]); // Decode base64 string
        const mimeString = base64Data.split(',')[0].split(':')[1].split(';')[0]; // Extract MIME type

        const byteArray = new Uint8Array(byteString.length);
        for (let i = 0; i < byteString.length; i++) {
            byteArray[i] = byteString.charCodeAt(i);
        }

        return new Blob([byteArray], { type: mimeString });
    };

    // Helper function to get the cropped image from the canvas
    const getCroppedImg = (image: HTMLImageElement, crop: Crop): string | null => {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        const pixelRatio = window.devicePixelRatio;

        const croppedWidth = crop.width ? crop.width * scaleX : 0;
        const croppedHeight = crop.height ? crop.height * scaleY : 0;

        canvas.width = croppedWidth * pixelRatio;
        canvas.height = croppedHeight * pixelRatio;

        const ctx = canvas.getContext('2d');
        if (!ctx) return null;

        ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
        ctx.imageSmoothingQuality = 'high';

        ctx.drawImage(
            image,
            crop.x! * scaleX, // x coordinate in the original image
            crop.y! * scaleY, // y coordinate in the original image
            croppedWidth, // width of cropped area in the original image
            croppedHeight, // height of cropped area in the original image
            0, // x coordinate to place on the canvas
            0, // y coordinate to place on the canvas
            croppedWidth, // width on the canvas
            croppedHeight // height on the canvas
        );

        // Convert the canvas to a data URL (base64 encoded image)
        const base64Image = canvas.toDataURL('image/jpeg');
        return base64Image;
    };

    return (
        <div style={{ maxWidth: '100%', maxHeight: '100%', overflow: 'hidden' }}>
            {showProgress ? 
                <>
                    <Progress value={uploadProgress} color={theme.colors.green[6]} />
                    <br></br>
                    <Text size="sm" style={{ marginTop: '5px', color: '#666' }}>
                        {t('cropper.image.uploading')}
                    </Text>
                    <br></br>
                </>
                :
                <div
                    style={{
                        maxWidth: '100%',
                        maxHeight: '80vh', // Ensure the image fits within a modal height
                        overflow: 'hidden', // Ensure it scrolls if the image is still large
                        display: 'flex',
                        flexDirection: 'column', // Stack items vertically
                        alignItems: 'center', // Optional: Center-align the crop and button
                    }}
                >
                    <ReactCrop crop={crop} aspect={4 / 3} onChange={c => {
                        setCropAdjusted(true);
                        setCrop(c)
                    }}>
                        <img
                            ref={imgRef}
                            src={src}
                            onLoad={onImageLoad}
                            alt="cropped img"
                            style={{
                                maxWidth: '100%',
                                maxHeight: '30vh', // Limit image height to fit in modal
                                objectFit: 'contain', // Ensure image is contained within this area
                            }}
                        />
                    </ReactCrop>

                    {cropAdjusted ? (
                        <Button onClick={onSubmit} style={{ backgroundColor: theme.colors.green[7], marginTop: '10px' }}>
                            {t('cropper.save.image')}
                        </Button>
                    ) : (
                        <Alert
                            variant="light"
                            color="cyan"
                                title={t('cropper.adjust.title')}
                            icon={<IconInfoCircle />}
                            style={{ marginTop: '10px' }} // Add margin between the image and alert
                        >
                                {t('cropper.adjust.subtitle')}
                        </Alert>
                    )}
                </div>
                
            }
           
        </div>
    );
}

export default CropperModal;
