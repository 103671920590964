import { useCallback } from 'react';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { TextInput, PasswordInput, Button, Paper, Title, Text, Group, Stack, Center, Box } from '@mantine/core';
import logo from './../../assets/logo_sm.png';
import { useTranslation } from 'react-i18next';

export const Register = () => {

    const { t } = useTranslation();

    const handleSubmit = useCallback(async e => {
        e.preventDefault();
        const { email, password, repeatPassword } = e.target.elements;

        if (password.value !== repeatPassword.value) {
            alert("Passwords do not match");
            return;
        }

        const auth = getAuth();
        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email.value, password.value);
            const user = userCredential.user;
            console.log(user);
            // Further logic for saving username and activationId can be added here
        } catch (e) {
            alert(e.message);
        }
    }, []);

    return (
        <Center style={{ height: '100vh' }}>
            <Box style={{ width: 400 }}>
                <Paper radius="md" p="xl" withBorder shadow="md">
                    <Center>
                        <img src={logo} alt="Logo" width={100} />
                    </Center>
                    <Title order={2} align="center" mt="md" mb="lg">
                        Nature Golf App Registration
                    </Title>
                    <form onSubmit={handleSubmit}>
                        <Stack spacing="md">
                            <TextInput
                                name="email"
                                label="E-Mail"
                                placeholder="your-email@example.com"
                                type="email"
                                required
                            />
                            <PasswordInput
                                name="password"
                                label="Password"
                                placeholder="Your password"
                                required
                            />
                            <PasswordInput
                                name="repeatPassword"
                                label="Repeat Password"
                                placeholder="Repeat your password"
                                required
                            />
                 
                            <Group position="apart">
                                <Button type="submit" fullWidth style={{ backgroundColor: "#008b47" }}>
                                    Register
                                </Button>
                            </Group>
                        </Stack>
                    </form>
                    <Text size="sm" align="center" mt="md">
                        Already have an account? <a href="/login">Login</a>
                    </Text>
                </Paper>
            </Box>
        </Center>
    );
};
