import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, Textarea, useMantineTheme } from '@mantine/core';
import { ClubFactItem } from './../../../model/ClubFactItem';
interface EcoFactsEditorProps {
    fact: ClubFactItem;
    updateFact: (fact: ClubFactItem) => void;
}

export function EcoFactsEditor({ fact, updateFact }: EcoFactsEditorProps) {

    const { t } = useTranslation();
    const theme = useMantineTheme();

    const [updatedFact, setUpdatedFact] = useState<ClubFactItem>();
   
    useEffect(() => {
        setUpdatedFact(fact);
    }, [fact])
   

    const saveChanges = () => {
        if (updatedFact) {
            updateFact(updatedFact);
        }
    }

    const buttonDisabled = !updatedFact?.title || !updatedFact?.description;

    return (
        <>
            {updatedFact &&
                <div>
                    <Input.Wrapper label={t('ecofacts.new.title')}>
                        <Input
                            value={updatedFact.title}
                            onChange={(event) => {
                                const newFactData = { ...updatedFact, title: event.currentTarget.value }
                                setUpdatedFact(newFactData)
                            }}
                        />
                    </Input.Wrapper>
                    <br></br>
                    { /* GOLF CLUB DESCRIPTION */}
                    <Textarea
                        placeholder={t('ecofacts.new.description')}
                        label={t('ecofacts.new.description')}
                        autosize
                        minRows={2}
                        value={updatedFact.description}
                        onChange={(event) => {
                            const newFactData = { ...updatedFact, description: event.currentTarget.value }
                            setUpdatedFact(newFactData)
                        }}
                    />
                    <br></br>
                    <Button onClick={saveChanges} color={theme.colors.green[7]} disabled={buttonDisabled}>
                        {t('ecofacts.new.save')}
                    </Button>
                    <br />
                </div>
            }
        </>
    )
}